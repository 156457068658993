
export default {
  props: {
    isHome: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      footerHyperlink: [
        {
          id: 1,
          name: "About Us",
          url: "about-us",
        },
        {
          id: 2,
          name: "Privacy Policy",
          url: "privacy-policy",
        },
        {
          id: 3,
          name: "Contact Us",
          url: "contact-us",
        },
      ],
    };
  },
  methods: {
    openSocialMedia(url) {
      window.open(url, "_blank");
    },
    selectLanguage(val) {
      this.$store.commit("public/SET_LANGUAGE", val);
      this.$i18n.setLocaleCookie(val);
    },
  },
};
