
export default {
  props: {
    isHome : {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      showDrawer :  this.drawerLogin
    }
  },
  methods: {
    handleLogin(){
      window.open(process.env.BASE_ACCOUNT + 'mb/login', '_self')
    }
  }
}

