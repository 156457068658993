
export default {
  components: {
    profilePicture: () => import("~/components/molecules/profile-picture"),
  },
  data: () => ({
    username: "",
    level: "",
    iconMember: {
      owner: "bx-crown font-text__orange",
      admin: " bx-check-shield font-text__orange",
      moderator: "bx-check-shield font-text__purple",
    },
  }),
  mounted() {
    this.$store.dispatch("auth/getUserInfoByToken").then(() => {
      this.username = this.$store.state.auth.user.myProfile.username;
      this.level = this.$store.state.auth.user.myProfile.level ?? "";
    });
  },
};
