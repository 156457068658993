
export default {
  components: {
    drawerLogin: () => import("./gallery-assets/login-drawer.vue"),
  },
  props: {
    isLogin: {
      type: Boolean | undefined,
    },
    medias: {
      type: Array,
      default: [],
    },
    isHome: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawerLogin: false,
      isAllowed: true,
      images: [],
      ImageCount: 0,
      gridClassName: {
        figure: "",
        imageContainer: "",
      },
    };
  },
  watch: {
    medias() {
      this.handleResize();
    },
  },
  mounted() {
    this.ImageCount = this.medias.length;
    this.images = this.ImageCount <= 5 ? this.medias : this.medias.slice(0, 5);
    this.imageFormationClassGenerator();
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleOpenImage() {
      if (this.isLogin === false) {
        this.isAllowed = false;
        this.drawerLogin = true;
      } else {
      }
    },
    handleResize() {
      let containerWidth = this.$refs.myFigure.clientWidth;
      this.$refs.myFigure.style.height = `${containerWidth / 2}px`;
    },
    imageFormationClassGenerator() {
      const count = this.medias.length;
      switch (count) {
        case 1:
          this.gridClassName = {
            figure: "image-1",
            imageContainer: "child-gallery-1",
            firstImage: "",
          };
          break;
        case 2:
          this.gridClassName = {
            figure: "image-2",
            imageContainer: "child-gallery-2",
            firstImage: "",
          };
          break;
        case 3:
          this.gridClassName = {
            figure: "image-3",
            imageContainer: "child-gallery-3",
            firstImage: "first-gallery-child",
          };
          break;
        case 4:
          this.gridClassName = {
            figure: "image-4",
            imageContainer: "child-gallery-4",
            firstImage: "",
          };
          break;
        case 5:
          this.gridClassName = {
            figure: "image-5",
            imageContainer: "child-gallery-5",
            firstImage: "first-gallery-child-more",
          };
          break;
        default:
          this.gridClassName = {
            figure: "image-5",
            imageContainer: "child-gallery-5",
            firstImage: "first-gallery-child-more",
          };
          break;
      }
    },
  },
};
