
export default {
  components: {
    gallery: () => import("~/components/organisms/gallery.vue"),
  },
  props: {
    defaultImage: {
      type: Array,
      default: [],
    },
    format: {
      type: Array,
      default: () => [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "image/webp",
        "image/heic",
        "video/mp4",
        "video/hevc",
      ],
    },
  },
  data: () => ({
    imageUrl: null,
    media_file: "",
    video_url: "",
    mediaImage: [],
  }),
  mounted() {
    if (this.defaultImage) {
      this.mediaImage = this.defaultImage;
    }
  },
  methods: {
    blobToBase64(blob) {
      const reader = new FileReader();
      reader.readAsDataURL(blob.raw);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    },
    async handleImageSuccess(file) {
      const valid = await this.beforeImageUpload(file.raw);
      if (!valid) {
        return;
      }
      const thumb = URL.createObjectURL(file.raw);
      if (file.raw && ["video/mp4", "video/hevc"].includes(file.raw.type)) {
        this.video_url = thumb;
      } else {
        this.imageUrl = thumb;
      }
      this.media_file = file.raw;
      const form = new FormData();
      form.append("file", this.media_file);
      this.$axios
        .post("https://apix.modoc.id/v1/upload", form, {
          Authorization: "Bearer " + this.$store.state.auth.token,
          "Content-Type": "multipart/form-data",
        })
        .then((res) => {
          this.mediaImage.push(res.data.body.link);

          this.$emit("onUpload", {
            files: this.mediaImage,
          });
        })
        .catch((err) => {
          throw err;
        });
    },
    beforeImageUpload(file) {
      let isJfif = false;
      if (file.name) {
        const filename = file.name.split(".");
        if (filename.length > 0 && filename[1] === "jfif") {
          isJfif = true;
        }
      }
      const isJPG = this.format.includes(file.type);
      const maxUploadSize = 2 * 1024 * 1024;
      const isLt10M = file.size / maxUploadSize;

      if (isJfif || !isJPG) {
        this.$notify.error({
          message: "Format gambar tidak sesuai",
          offset: 100,
        });
        return false;
      }
      if (isLt10M > 1) {
        this.$notify.error({
          message: "Ukuran maksimal gambar yang diupload adalah 2MB!",
          offset: 100,
        });
        return false;
      }
      return isJPG && isLt10M;
    },
  },
};
